.add-container {
    background-color: #CAD2DD;
    color: #001C46;
    width: 100%;
    position: relative;
    z-index: 8;
    padding-top: 1%;
    padding-bottom: 1%;
}

.add-container h1 {
    text-align: center;
}

@media (max-width: 600px) {
    

    .add-container > h1 {
        font-size: 1.8rem;
    }
}

@media (max-width: 500px) {
    

    .add-container > h1 {
        font-size: 1.4rem;
    }
}

@media (max-width: 350px) {
    

    .add-container > h1 {
        font-size: 1.2rem;
    }
}