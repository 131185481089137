.second-add-container {
    background-color: #CAD2DD;
    color: #001C46;
    width: 100%;
    position: relative;
    z-index: 8;
    padding-top: 1%;
    padding-bottom: 1%;
}

.second-add-container h1 {
    text-align: center;
    font-weight: 600;
}

.second-add-container h2 {
    text-align: center;
    font-weight: 500;
}

@media (max-width: 750px) {

    .second-add-container h1 {
        font-size: 1.5rem;
    }
    
    .second-add-container h2 {
        font-size: 1.1rem;
    }
    
}

@media (max-width: 500px) {

    .second-add-container h1 {
        font-size: 1.2rem;
    }
    
    .second-add-container h2 {
        font-size: 0.9rem;
    }
    

}

@media (max-width: 380px) {

    .second-add-container h1 {
        font-size: 1rem;
    }
    
    .second-add-container h2 {
        font-size: 0.7rem;
    }
    
    

}