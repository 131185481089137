.about-container {
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 6%;
}

.about-content {
    position: relative;
    padding-top: 8%;
    z-index: 9;
    justify-content: space-evenly;
    display: flex;
    max-width: 1600px;
    padding-left: 5%;
    padding-right: 5%;
    text-align: left;
}

.about-text-container h1 {
    font-size: 4rem;
    text-align: left;
    color: #063A88;
}

.about-text-container h2 {
    font-size: 2.5rem;
    text-align: left;
    color: #66D0DA;
}

.about-text-container p {
    font-size: 1.5rem;
    margin-top: 5%;
    text-align: left;
    width: 80%;
}

.about-icon-logo {
    width: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
}

.about-icon-logo img {
    width: 350px;
}

@media (max-width: 1100px) {

    .about-content {
        padding-top: 6%;
    }

    .about-text-container h1 {
        font-size: 3rem;
        text-align: left;
        color: #063A88;
    }
    
    .about-text-container h2 {
        font-size: 2rem;
        text-align: left;
        color: #66D0DA;
    }
    
    .about-text-container p {
        font-size: 1.3rem;
        margin-top: 5%;
        text-align: left;
        width: 80%;
    }

    .about-icon-logo img {
        width: 320px;
    }
    

}

@media (max-width: 900px) {
    
    .about-content {
        display: inline;
    }

    .about-text-container h1 {
        font-size: 3.5rem;
        text-align: left;
        color: #063A88;
    }
    
    .about-text-container h2 {
        font-size: 2.2rem;
        text-align: left;
        color: #66D0DA;
    }
    
    .about-text-container p {
        font-size: 1.2rem;
        margin-top: 2%;
        text-align: left;
        width: 100%;
    }

    .about-icon-logo {
        width: auto;
        margin-top: 3%;
        justify-content: center;
    }

    .about-icon-logo img {
        width: 320px;
    }

}

@media (max-width: 700px) {

    .about-text-container h1 {
        font-size: 2.8rem;
    }
    
    .about-text-container h2 {
        font-size: 1.9rem;
    }
    
    .about-text-container p {
        font-size: 1.1rem;
        margin-top: 2%;
    }

    .about-icon-logo {
        margin-top: 2%;
    }

    .about-icon-logo img {
        width: 280px;
    }
}

@media (max-width: 450px) {

    .about-text-container h1 {
        font-size: 2rem;
    }
    
    .about-text-container h2 {
        font-size: 1.2rem;
    }
    
    .about-text-container p {
        font-size: 0.9rem;
        margin-top: 2%;
    }

    .about-icon-logo {
        margin-top: 2%;
    }

    .about-icon-logo img {
        width: 200px;
    }
}
