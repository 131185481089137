.navbar-max-width {
    width: 100%;
    background-color: #FCFCFC;
    position: fixed;
    z-index: 10;
}

.navbar-container {
    max-width: 1600px;
    display: flex;
    justify-content: space-around;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
}


.link--title-logo {
    position: relative;
    z-index: 9;
    width: 200px;
}

.link-services, .link-contact {
   
    color: #03285F;
    text-decoration: none;
    font-size: 1.7rem;
}

.link-contact {
    width: 100%;
    padding-left: 10%;
}

.link-services:visited, .link-contact:visited {
    
    color: #03285F;
    text-decoration: none;
}

.link-services-h1, .link-contact-h1 {
    font-style: normal;
    font-weight: 400;
}

.navbar-mobile {
    display: none;
}

.navbar-container-links {
    display: flex;
    white-space: nowrap;
    margin-top: auto;
    margin-bottom: auto;
}

@media (max-width: 1300px) {

    .logo {
        width: 180px;
    }

    .link-services-h1, .link-contact-h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
    }

}

@media (max-width: 1100px) {

    .navbar-container {
        display: none;
    }

}