.navlinks-container {
    position: absolute; 
    display: flex;
    bottom: -80px;
    height: 80px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.941);
    z-index: 8;
    justify-content: space-evenly;
    align-items: center;
}

.navlinks-link {
    color: #03285F;
    text-decoration: none;
    font-size: 1.7rem
}

.navlinks-link:visited {
    
    color: #03285F;
    text-decoration: none;
}

@media (max-width: 800px) {
    .navlinks-container {
        bottom: -80px;
        height: 80px;
        justify-content: space-evenly;
    }

    .navlinks-link {
        font-size: 1.5rem
    }
}

@media (max-width: 550px) {
    .navlinks-container {
        bottom: -80px;
        height: 80px;
        justify-content: space-evenly;
    }

    .navlinks-link {
        font-size: 1.2rem
    }
}

@media (max-width: 400px) {
    .navlinks-container {
        bottom: -120px;
        height: 120px;
        flex-direction: column;
    }

    .navlinks-link {
        font-size: 1.3rem

    }
}