.header-container {
    color: #001C46;
    justify-content: center;
    position: relative;
    padding-top: 2%;
}

.header-content {
    position: relative;
    display: flex;
    padding-top: 8rem;
    max-width: 1600px;
    z-index: 9;
    padding-bottom: 5%;
    justify-content: right;
    margin-left: auto;
    margin-right: auto;
}

.header-text {
    width: 50%;
    position: absolute;
    z-index: 5;
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(7px);
    background: rgba(255, 255, 255, 0.5);
    top: 25%;
    left: 20%;
    padding-left: 3%;
    padding-top: 2%;
    padding-bottom: 4%;
}

.header-h1 {
    font-size: 3rem;
    font-family: 'Lora', serif;
    font-weight: 600;

}

.header-p {
    font-size: 1.7rem;
    margin-top: 3%;
    margin-bottom: 3%;
    width: 90%;
}

.header-button {
    position: relative;
    z-index: 8;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50px;
    border-style: solid;
    border-color: #CAD2DD;
    color: #001C46;
    background-color: white;
    font-family: var(--main-font);
    font-weight: 600;
    font-size: 1.2rem;
    text-decoration: none;
}

.header-button:active {
    transform: scale(0.9);
}

.header-button:visited {
    text-decoration: none;
    color: #001C46;
}

.dots {
    position: absolute;
    left: 10%;
}

.background-picture {
    width: 800px;
    margin-right: 5%;
}

.mobile-dots, .mobile-background-picture {
    display: none;
}

@media (max-width: 1100px) {

    .dots, .background-picture {
        display: none;
    }

    .mobile-dots {
        display: inline;
        position: absolute;
        left: 15%;
        top: 56%;
        width: 135px;
        z-index: 6;
    }

    .mobile-background-picture {
        display: inline;
        width: 70%;
        margin-right: 5%;
    }
    
    .header-text {
        width: 50%;
        padding-top: 3%;
        padding-bottom: 6.5%;
        top: 28%;
    }

    .header-h1 {
        font-size: 2.5rem;
        font-family: 'Lora', serif;
        font-weight: 600;
        margin-bottom: 5%;
    
    }
    
    .header-p {
        font-size: 1.4rem;
        margin-top: 5%;
        margin-bottom: 8%;
        width: 90%;
    }

    .header-button {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 50px;
        border-width: 5px;
        font-weight: 500;
        font-size: 1.1rem;
    }
}


@media (max-width: 900px) {
    
    .header-content {
        padding-top: 20%;
    }

    .mobile-dots {
        left: 14%;
        top: 65%;
        width: 100px;
    }
    
    .header-text {
        padding-top: 3%;
        padding-bottom: 6.5%;
        top: 28%;
        left: 14%;
        width: 65%;
    }

    .header-h1 {
        font-size: 2.2rem;
        font-family: 'Lora', serif;
        font-weight: 600;
        margin-bottom: 5%;
    
    }
    
    .header-p {
        font-size: 1.2rem;
        margin-top: 5%;
        margin-bottom: 8%;
        width: 90%;
    }

    .header-button {
        font-size: 0.9rem;
    }
}



@media (max-width: 650px) {

    


    .mobile-dots {
        left: 14%;
        top: 72%;
        width: 75px;
    }
    
    .header-text {
        padding-top: 3%;
        padding-bottom: 6.5%;
        top: 28%;
        left: 14%;
        width: 65%;
    }

    .header-h1 {
        font-size: 1.9rem;
        font-family: 'Lora', serif;
        font-weight: 600;
        margin-bottom: 5%;
    
    }
    
    .header-p {
        font-size: 1.2rem;
        margin-top: 5%;
        margin-bottom: 8%;
        width: 90%;
    }

    .header-button {
        font-size: 0.9rem;
    }
    
}

@media (max-width: 500px) {

    .mobile-dots {
        left: 14%;
        top: 74%;
        width: 50px;
    }
    
    .header-text {
        padding-top: 3%;
        padding-bottom: 6.5%;
        top: 28%;
        left: 14%;
        width: 65%;
    }

    .header-h1 {
        font-size: 1.5rem;
        font-family: 'Lora', serif;
        font-weight: 600;
        margin-bottom: 5%;
    
    }
    
    .header-p {
        font-size: 1rem;
        margin-top: 5%;
        margin-bottom: 8%;
        width: 90%;
    }

    .header-button {
        font-size: 0.7rem;
    }
}


@media (max-width: 400px) {

    .mobile-dots {
        left: 14%;
        top: 76%;
        width: 45px;
    }
    
    .header-text {
        padding-top: 4%;
        padding-bottom: 8.5%;
        top: 28%;
        left: 14%;
        width: 65%;
    }

    .header-h1 {
        font-size: 1.2rem;
        font-family: 'Lora', serif;
        font-weight: 600;
        margin-bottom: 5%;
    
    }
    
    .header-p {
        font-size: 0.8rem;
        margin-top: 5%;
        margin-bottom: 8%;
        width: 90%;
    }

    .header-button {
        font-size: 0.6rem;
    }
}
@media (max-width: 350px) {

    .mobile-dots {
        left: 14%;
        top: 76%;
        width: 35px;
    }
    
    .header-text {
        padding-top: 4%;
        padding-bottom: 8.5%;
        top: 25%;
        left: 14%;
        width: 65%;
    }

    .header-h1 {
        font-size: 1.15rem;
        font-family: 'Lora', serif;
        font-weight: 600;
        margin-bottom: 5%;
    
    }
    
    .header-p {
        font-size: 0.7rem;
        margin-top: 5%;
        margin-bottom: 8%;
        width: 90%;
    }

    .header-button {
        font-size: 0.6rem;
    }
}
