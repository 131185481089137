.footer-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    background-color: #001C46;
    color: white;
    position: relative;
    overflow: hidden;
}

@media (max-width: 600px) {
    

    .footer-container > p {
        font-size: 0.8rem;
    }
}

@media (max-width: 500px) {
    

    .footer-container > p {
        font-size: 0.6rem;
    }
}