.form-background {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background: #d2e5ff;
    padding-bottom: 5%;
}

.form--container {
    width: 80%;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background: #CAD2DD;
    padding: 4% 6% 2% 6%;
    border-radius: 35px;
    backdrop-filter: blur(3px);
}

.form--container textarea {
    font-family: 'Poppins', sans-serif;
    min-height: 40px;
}

.form--container h1 {
    margin-bottom: 5%;
    font-size: 2.1rem;
    font-weight: 500;
    text-align: center;
}

.form--container label {
    font-size: 1.2rem;
}

.form--container .select {
    border-radius: 50px;
    font-size: 1.1rem;
    backdrop-filter: blur(3px);
    border-top: 1.5px solid rgba(255, 255, 255, 0.7);
    border-left: 1.5px solid rgba(255, 255, 255, 0.7);
    width: 142%;
    padding: 1% 5% 1% 5%;
    background: white;
    margin-bottom: 8%;
}



.form--container .input {
    border-radius: 50px;
    font-size: 1.1rem;
    backdrop-filter: blur(3px);
    border-top: 1.5px solid rgba(255, 255, 255, 0.7);
    border-left: 1.5px solid rgba(255, 255, 255, 0.7);
    width: 100%;
    padding: 1% 5% 1% 5%;
    background: white;
    margin-bottom: 4%;
}

.form--container .input-button {
    border-radius: 50px;
    backdrop-filter: blur(3px);
    border-top: 2px solid rgba(255, 255, 255, 0.7);
    border-left: 2px solid rgba(255, 255, 255, 0.7);
    width: 30%;
    background: white;
    color: black;
    font-size: 1.1em;
    cursor: pointer;
    margin-bottom: 35px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    position: relative;
    z-index: 9;
}

.input-button:active {
    transform: scale(0.9);
}

.form-double-input {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4%;
}

.form-four-input {
    display: flex;
    margin-bottom: 4%;
}

.form-four-input div {
    width: 40%;
}

.fouth-input {
    border-radius: 50px;
    font-size: 1rem;
    border-top: 1.5px solid rgba(255, 255, 255, 0.7);
    border-left: 1.5px solid rgba(255, 255, 255, 0.7);
    padding: 1% 0% 1% 4%;
    background: white;
    margin-bottom: 8%;
    flex: 1 1 0px;
    justify-content: space-evenly;
}

.form-select-input {
    display: flex;
    margin-bottom: 4%;
}

.input-select {
    width: 43%;
    margin-left: auto;
}

.form-radio-type {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4%;
}

@media (max-width: 1400px) {
    
    .form-four-input {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 4%;
    }

    .form-four-input div {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .form-four-input input {
        width: 90%;
    }

    .fouth-input {
        border-radius: 50px;
        font-size: 1rem;
        border-top: 1.5px solid rgba(255, 255, 255, 0.7);
        border-left: 1.5px solid rgba(255, 255, 255, 0.7);
        padding: 1% 0% 1% 4%;
        background: white;
        margin-bottom: 8%;
        flex: 1 1 0px;
        justify-content: space-evenly;
    }
}

@media (max-width: 1200px) {
    
    .form-four-input {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 4%;
    }

    .form-four-input div {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .form-four-input input {
        width: 90%;
    }

    .fouth-input {
        border-radius: 50px;
        font-size: 1rem;
        margin-bottom: 8%;
        flex: 1 1 0px;
        justify-content: space-evenly;
    }

    .form-double-input {
        display: flex;
        margin-bottom: 4%;
    }

    .form-double-input div{
        width: 100%;
    }

    .form-double-input-phone {
        margin-right: 5%;
    }

    .form--container h1 {
        font-size: 1.8rem;
    }
    
    .form--container label {
        font-size: 1rem;
    }
    
    .form--container .select {
        font-size: 1rem;
    }
    
    
    
    .form--container .input {
        font-size: 1rem;
    }
    
}

@media (max-width: 750px) {

    .form--container h1 {
        font-size: 1.6rem;
    }
    
    .form--container label {
        font-size: 0.8rem;
    }
    
    .form--container .select {
        font-size: 0.8rem;
    }

    .form--container .input {
        font-size: 0.8rem;
    }
    
    .form--container .input-button {
        font-size: 0.8rem;
    }

}

@media (max-width: 550px) {

    .form--container h1 {
        font-size: 1.6rem;
    }
    
    .form--container label {
        font-size: 0.8rem;
    }
    
    .form--container .select {
        font-size: 0.8rem;
        width: 80%;
    }
    
    .form--container .input {
        font-size: 0.8rem;
    }
    
    .form--container .input-button {
        font-size: 0.8rem;
    }
    
}

@media (max-width: 420px) {

    .form--container h1 {
        font-size: 1.4rem;
    }
    
    .form--container label {
        font-size: 0.6rem;
    }
    
    .form--container .select {
        font-size: 0.6rem;
        width: 80%;
    }
    
    .form--container .input {
        font-size: 0.6rem;
    }
    
    .form--container .input-button {
        font-size: 0.8rem;
    }
    
}